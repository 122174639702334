import map from 'lodash/map';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';
import { MessageFileAttachments } from '../../../../../../../messagesTypes';

import { SubmitResultsToTaskModalButton } from '../../../../../../../../tasks/components/modalButtons/SubmitResultsToTaskModalButton';

import { convertFileToDropzoneFile } from '../../../../../../../../../helpers/dropzone/DropzoneHelper/utils/convertFileToDropzoneFile';

import { tasksKeys } from '../../../../../../../../../locales/keys';

interface MessageDropdownSendResultProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
}

function MessageDropdownSendResult({
  message
}: MessageDropdownSendResultProps) {
  const attachments = message.fileAttachments as MessageFileAttachments;

  const initialFiles = convertFileToDropzoneFile(attachments);

  const initialFileIds = map(attachments, 'id');

  return (
    <SubmitResultsToTaskModalButton
      taskNanoId={message.task?.nanoId}
      initialFileIds={initialFileIds}
      initialFiles={initialFiles}
      i18nText={tasksKeys.submitAsTaskResult}
      className="text-left flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
    />
  );
}

export default MessageDropdownSendResult;
