import React, { useCallback } from 'react';

import { PureButtonHelper } from '../../../../../../../../../helpers/buttons/PureButtonHelper';

import { useDownloadNanoId } from '../../../../../../../../downloads/hooks/useDownloadNanoId';
import { useDownloadMessageSelectedProducts } from '../../../../../../../hooks/useDownloadMessageSelectedProducts';

import {
  DownloadMessageSelectedProductsQueryResponse,
  DOWNLOAD_MESSAGE_SELECTED_PRODUCTS_QUERY
} from '../../../../../../../queries/downloadMessageSelectedProducts.query';

import { selectedProductsKeys } from '../../../../../../../../../locales/keys';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';

interface MessageDropdownDownloadSelectedProductProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
}

function MessageDropdownDownloadSelectedProducts({
  message
}: MessageDropdownDownloadSelectedProductProps) {
  const { downloadNanoId } = useDownloadNanoId();

  const {
    downloadMessageSelectedProducts,
    downloadMessageSelectedProductsLoading
  } = useDownloadMessageSelectedProducts<DownloadMessageSelectedProductsQueryResponse>(
    {
      query: DOWNLOAD_MESSAGE_SELECTED_PRODUCTS_QUERY
    }
  );

  const handleDownloadTaskResults = useCallback<() => void>(
    () =>
      downloadMessageSelectedProducts({
        uuid: message.uuid,
        deviceNanoId: downloadNanoId
      }),
    [message, downloadNanoId, downloadMessageSelectedProducts]
  );

  return (
    <PureButtonHelper
      className="text-left flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      disabled={downloadMessageSelectedProductsLoading}
      i18nText={selectedProductsKeys.download}
      onClick={handleDownloadTaskResults}
    />
  );
}

export default MessageDropdownDownloadSelectedProducts;
