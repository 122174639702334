import { Fragment } from 'react';
import size from 'lodash/size';
import sumBy from 'lodash/sumBy';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import compact from 'lodash/compact';

import { ItemMessagesListMessageMenuDropdownMessage } from './ItemMessagesListMessageMenuDropdown.types';

import { PositionStyleEnum } from '../../../../../../../types';
import {
  FetchMessagesCacheKey,
  MessageProjectUUID,
  MessageTaskUUID
} from '../../../../../messagesTypes';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { MessageDropdownFavoriteButton } from './components/MessageDropdownFavoriteButton';
import { MessageDropdownFlaggedButton } from './components/MessageDropdownFlaggedButton';
import { MessageDropdownPinnedButton } from './components/MessageDropdownPinnedButton';
import { MessageDropdownPropagateModalButton } from './components/MessageDropdownPropagateModalButton';
import { MessageDropdownRedButton } from './components/MessageDropdownRedButton';
import { MessageDropdownVFCButton } from './components/MessageDropdownVFCButton';
import { MessageDropdownDownloadAttachments } from './components/MessageDropdownDownloadAttachments';
import { MessageDropdownDownloadFileAttachments } from './components/MessageDropdownDownloadFileAttachments';
import { MessageDropdownDownloadSelectedProducts } from './components/MessageDropdownDownloadSelectedProducts';
import { MessageDropdownDownloadSelectedLifestyles } from './components/MessageDropdownDownloadSelectedLifestyles';
import { MessageDropdownAddToDescriptionButton } from './components/MessageDropdownAddToDescriptionButton';
import { MessageDropdownCopyIdButton } from './components/MessageDropdownCopyIdButton';
import { MessageDropdownCopyUuidButton } from './components/MessageDropdownCopyUuidButton';
import { MessageDropdownForwardModalButton } from './components/MessageDropdownForwardModalButton';
import { MessageDropdownSendResult } from './components/MessageDropdownSendResult';
import { SubmitSourceFilesToTaskModalButton } from '../../../../../../tasks/components/modalButtons/SubmitSourceFilesToTaskModalButton';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { TooltipDropdownHelper } from '../../../../../../../helpers/dropdowns/TooltipDropdownHelper';
import { DropdownDivider } from '../../../../../../../helpers/DropdownDivider';

import { words, tasksKeys } from '../../../../../../../locales/keys';

import { MessagesPermissions } from '../../../../../messagesConstants';
import { TasksPermissions } from '../../../../../../tasks/tasksConstants';

interface ItemMessagesListMessageMenuDropdownProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  forwardMessageProjectUuid: MessageProjectUUID;
  forwardMessageTaskUuid?: MessageTaskUUID;
  withPropagateButton?: boolean;
  tooltipSingleton?: boolean;
}

function ItemMessagesListMessageMenuDropdown({
  message,
  messagesCacheKey,
  forwardMessageProjectUuid,
  forwardMessageTaskUuid,
  withPropagateButton = false,
  tooltipSingleton
}: ItemMessagesListMessageMenuDropdownProps) {
  const currentUser = useCurrentUser();

  const quantityTypesAttachments = sumBy(
    [
      message.fileAttachments,
      message.selectedProducts,
      message.selectedLifestyles
    ],
    (attachments) => (isEmpty(attachments) ? 0 : 1)
  );

  const withSubmitAsTaskResultsButton =
    message.task && size(message.fileAttachments) > 0;

  const withSubmitSourceFilesButton =
    size(message.fileAttachments) > 0 &&
    message.task?.nanoId &&
    some(
      compact([
        MessagesPermissions.READ_MESSAGE_FILE_ATTACHMENTS_SUBMIT_SOURCE_FILES_BUTTON,
        currentUser.nanoId === message.user.nanoId
          ? MessagesPermissions.READ_SELF_MESSAGE_FILE_ATTACHMENTS_SUBMIT_SOURCE_FILES_BUTTON
          : null
      ]),
      (action) => currentUser.hasPermissions(action)
    );

  return (
    <TooltipDropdownHelper
      tooltipSingleton={tooltipSingleton}
      tooltipI18nText={words.more}
      popoverPositionStyle={PositionStyleEnum.fixed}
    >
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_COPY_ID_BUTTON}
      >
        <MessageDropdownCopyIdButton message={message} />
      </CheckPermissions>
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_COPY_UUID_BUTTON}
      >
        <MessageDropdownCopyUuidButton message={message} />
      </CheckPermissions>
      {quantityTypesAttachments > 1 ? (
        <CheckPermissions
          action={MessagesPermissions.READ_MESSAGE_DOWNLOAD_ATTACHMENTS_BUTTON}
        >
          <MessageDropdownDownloadAttachments message={message} />
        </CheckPermissions>
      ) : (
        <Fragment>
          {size(message.fileAttachments) > 0 && (
            <CheckPermissions
              action={
                MessagesPermissions.READ_MESSAGE_DOWNLOAD_FILE_ATTACHMENTS_BUTTON
              }
            >
              <MessageDropdownDownloadFileAttachments message={message} />
            </CheckPermissions>
          )}
          {size(message.selectedProducts) > 0 && (
            <CheckPermissions
              action={
                MessagesPermissions.READ_MESSAGE_DOWNLOAD_SELECTED_PRODUCTS_BUTTON
              }
            >
              <MessageDropdownDownloadSelectedProducts message={message} />
            </CheckPermissions>
          )}
          {size(message.selectedLifestyles) > 0 && (
            <CheckPermissions
              action={
                MessagesPermissions.READ_MESSAGE_DOWNLOAD_SELECTED_LIFESTYLES_BUTTON
              }
            >
              <MessageDropdownDownloadSelectedLifestyles message={message} />
            </CheckPermissions>
          )}
        </Fragment>
      )}
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_ADD_TO_DESCRIPTION_BUTTON}
      >
        <MessageDropdownAddToDescriptionButton message={message} />
      </CheckPermissions>
      {withPropagateButton && (
        <CheckPermissions
          action={MessagesPermissions.READ_MESSAGE_PROPAGATE_BUTTON}
        >
          <MessageDropdownPropagateModalButton
            message={message}
            messagesCacheKey={messagesCacheKey}
          />
        </CheckPermissions>
      )}
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_VISIBLE_FOR_CLIENTS_BUTTON}
      >
        <MessageDropdownVFCButton
          message={message}
          messagesCacheKey={messagesCacheKey}
        />
      </CheckPermissions>
      <CheckPermissions action={MessagesPermissions.READ_MESSAGE_RED_BUTTON}>
        <MessageDropdownRedButton
          message={message}
          messagesCacheKey={messagesCacheKey}
        />
      </CheckPermissions>

      {withSubmitAsTaskResultsButton && (
        <CheckPermissions
          action={TasksPermissions.READ_TASK_SUBMIT_RESULTS_BUTTON}
        >
          <MessageDropdownSendResult message={message} />
        </CheckPermissions>
      )}

      {withSubmitSourceFilesButton ? (
        <SubmitSourceFilesToTaskModalButton
          taskNanoId={message.task.nanoId}
          className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
          i18nText={tasksKeys.submitAsSourceFile}
          initialFiles={message.fileAttachments}
        />
      ) : null}

      <CheckPermissions
        actions={[
          MessagesPermissions.READ_MESSAGE_FLAGGED_BUTTON,
          MessagesPermissions.READ_MESSAGE_FAVORITE_BUTTON,
          MessagesPermissions.READ_MESSAGE_FORWARD_BUTTON,
          MessagesPermissions.READ_MESSAGE_PINNED_BUTTON
        ]}
      >
        <DropdownDivider />
      </CheckPermissions>
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_FLAGGED_BUTTON}
      >
        <MessageDropdownFlaggedButton
          message={message}
          messagesCacheKey={messagesCacheKey}
        />
      </CheckPermissions>
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_FAVORITE_BUTTON}
      >
        <MessageDropdownFavoriteButton
          message={message}
          messagesCacheKey={messagesCacheKey}
        />
      </CheckPermissions>
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_FORWARD_BUTTON}
      >
        {!message.body && size(message.fileAttachments) > 0 && (
          <MessageDropdownForwardModalButton
            message={message}
            messagesCacheKey={messagesCacheKey}
            projectUuid={forwardMessageProjectUuid}
            taskUuid={forwardMessageTaskUuid}
          />
        )}
      </CheckPermissions>
      <CheckPermissions action={MessagesPermissions.READ_MESSAGE_PINNED_BUTTON}>
        <MessageDropdownPinnedButton
          message={message}
          messagesCacheKey={messagesCacheKey}
        />
      </CheckPermissions>
    </TooltipDropdownHelper>
  );
}

export default ItemMessagesListMessageMenuDropdown;
