import React, { useCallback } from 'react';
import compact from 'lodash/compact';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';
import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';

import {
  TOGGLE_MESSAGE_FAVORITE_QUERY,
  ToggleMessageFavoriteRecordType
} from '../../../../../../../queries/toggleMessageFavorite.query';

import { useToggleMessageFavorite } from '../../../../../../../hooks/useToggleMessageFavorite';

import { PureButtonHelper } from '../../../../../../../../../helpers/buttons/PureButtonHelper';

import { TaskCache } from '../../../../../../../../tasks/TaskCache';
import { ProjectCache } from '../../../../../../../../projects/ProjectCache';
import { words } from '../../../../../../../../../locales/keys';

interface MessageDropdownFavoriteButtonProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
  messagesCacheKey: FetchMessagesCacheKey;
}

function MessageDropdownFavoriteButton({
  message,
  messagesCacheKey
}: MessageDropdownFavoriteButtonProps) {
  const { toggleMessageFavorite, toggleMessageFavoriteLoading } =
    useToggleMessageFavorite<ToggleMessageFavoriteRecordType>({
      cacheKeys: compact([
        messagesCacheKey,
        message.project?.nanoId
          ? ProjectCache.favoriteMessagesCacheKey(message.project.nanoId)
          : null,
        message.task?.nanoId
          ? TaskCache.favoriteMessagesCacheKey(message.task.nanoId)
          : null
      ]),
      query: TOGGLE_MESSAGE_FAVORITE_QUERY
    });

  const handleToggleMessageFavorite = useCallback<() => void>(
    () => toggleMessageFavorite({ uuid: message.uuid }),
    [message.uuid, toggleMessageFavorite]
  );

  return (
    <PureButtonHelper
      className="text-left flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      disabled={toggleMessageFavoriteLoading}
      i18nText={words.favorite}
      onClick={handleToggleMessageFavorite}
    />
  );
}

export default MessageDropdownFavoriteButton;
