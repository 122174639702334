import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import {
  ClassName,
  I18nText,
  IsDisabled,
  FetchItemsCacheKey
} from '../../../../../types';
import { MessageUUID } from '../../../../messages/messagesTypes';

import { MESSAGE_SEND_TO_EMAIL_NOTIFICATION_QUERY } from '../../../queries/messageSendEmailNotification.query';

import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useMessageSendEmailNotification } from '../../../hooks/useMessageSendEmailNotification';

import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';

interface MessageSendEmailNotificationButtonProps {
  messageUuid: MessageUUID;
  className?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  i18nText?: I18nText;
  disabled?: IsDisabled;
  cacheKeys?: FetchItemsCacheKey[];
}

// TODO draft SendEmailNotification
function MessageSendEmailNotificationButton({
  messageUuid,
  disabled,
  className,
  icon,
  iconClassName,
  i18nText,
  cacheKeys
}: MessageSendEmailNotificationButtonProps) {
  const {
    messageSendEmailNotification,
    messageSendEmailNotificationLoading,
    messageSendEmailNotificationErrorMessage
  } = useMessageSendEmailNotification({
    query: MESSAGE_SEND_TO_EMAIL_NOTIFICATION_QUERY,
    cacheKeys
  });

  useShowToastOnErrorChange({
    error: messageSendEmailNotificationErrorMessage
  });

  const handleMessageSendEmailNotificationButton = useCallback(
    () => messageSendEmailNotification({ uuid: messageUuid }),
    [messageSendEmailNotification, messageUuid]
  );

  return (
    <PureIconButtonHelper
      className={className}
      disabled={messageSendEmailNotificationLoading || disabled}
      icon={icon}
      iconClassName={iconClassName}
      onClick={handleMessageSendEmailNotificationButton}
      i18nText={i18nText}
    />
  );
}

export default MessageSendEmailNotificationButton;
