import cl from 'classnames';
import some from 'lodash/some';
import compact from 'lodash/compact';

import { ClassName, PositionStyleEnum } from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { FetchMessagesCacheKey } from '../../../../../messagesTypes';
import {
  MessagesListMessageFileAttachmentButtonsFileAttachment,
  MessagesListMessageFileAttachmentButtonsMessage
} from './MessagesListMessageFileAttachmentButtons.types';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { DeleteFileAttachmentButton } from '../../../../../../fileAttachments/components/buttons/DeleteFileAttachmentButton';
import { DownloadFileAttachmentModalButton } from '../../../../../../fileAttachments/components/buttons/DownloadFileAttachmentModalButton';
import { SubmitSourceFilesToTaskModalButton } from '../../../../../../tasks/components/modalButtons/SubmitSourceFilesToTaskModalButton';

import { TooltipDropdownHelper } from '../../../../../../../helpers/dropdowns/TooltipDropdownHelper';
import { PopoverPlacement } from '../../../../../../../helpers/Popover/popoverConstants';

import { words, tasksKeys } from '../../../../../../../locales/keys';

import { MessagesPermissions } from '../../../../../messagesConstants';

interface MessagesListMessageFileAttachmentButtonsProps {
  addClassName?: ClassName;
  messagesCacheKey?: FetchMessagesCacheKey;
  fileAttachment: MessagesListMessageFileAttachmentButtonsFileAttachment;
  message: MessagesListMessageFileAttachmentButtonsMessage;
}

function MessagesListMessageFileAttachmentButtons({
  addClassName,
  messagesCacheKey,
  fileAttachment,
  message
}: MessagesListMessageFileAttachmentButtonsProps) {
  const currentUser = useCurrentUser();

  const withDownloadButton =
    fileAttachment.uuid &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_DOWNLOAD_FILE_ATTACHMENT_BUTTON
    );

  const withDeleteButton =
    fileAttachment.uuid &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_DELETE_FILE_ATTACHMENT_BUTTON
    );

  const withSubmitSourceFilesButton =
    fileAttachment.uuid &&
    message.task?.nanoId &&
    some(
      compact([
        MessagesPermissions.READ_MESSAGE_FILE_ATTACHMENT_SUBMIT_SOURCE_FILES_BUTTON,
        currentUser.nanoId === message.user.nanoId
          ? MessagesPermissions.READ_SELF_MESSAGE_FILE_ATTACHMENT_SUBMIT_SOURCE_FILES_BUTTON
          : null
      ]),
      (action) => currentUser.hasPermissions(action)
    );

  if (
    !withDownloadButton &&
    !withDeleteButton &&
    !withSubmitSourceFilesButton
  ) {
    return null;
  }

  return (
    <div
      className={cl(
        'absolute z-10 right-0 group-hover/item:opacity-100 opacity-0 p-1 transition duration-150 hover:ease-in',
        addClassName
      )}
    >
      <div className="relative">
        <TooltipDropdownHelper
          buttonClassName="py-1 pl-1 pr-1 rounded-md bg-white dark:bg-gray-800 leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
          icon={IconsEnum.DOTS_HORIZONTAL_OUTLINE}
          popoverPositionStyle={PositionStyleEnum.fixed}
          tooltipI18nText={words.more}
          dropdownPlacement={PopoverPlacement.AUTO_END}
        >
          {withDownloadButton && (
            <DownloadFileAttachmentModalButton
              fileAttachmentUuid={fileAttachment.uuid}
              className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
              i18nText={words.download}
            />
          )}
          {withSubmitSourceFilesButton && message.task?.nanoId ? (
            <SubmitSourceFilesToTaskModalButton
              taskNanoId={message.task.nanoId}
              className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
              i18nText={tasksKeys.submitAsSourceFile}
              initialFile={fileAttachment}
            />
          ) : null}
          {withDeleteButton && (
            <DeleteFileAttachmentButton
              cacheKey={messagesCacheKey}
              fileAttachmentUuid={fileAttachment.uuid}
              className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
              i18nText={words.remove}
            />
          )}
        </TooltipDropdownHelper>
      </div>
    </div>
  );
}

export default MessagesListMessageFileAttachmentButtons;
