import React, { useCallback } from 'react';
import size from 'lodash/size';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';

import {
  DownloadMessageAttachmentsQueryResponse,
  DOWNLOAD_MESSAGE_ATTACHMENTS_QUERY
} from '../../../../../../../queries/downloadMessageAttachments.query';

import { useDownloadNanoId } from '../../../../../../../../downloads/hooks/useDownloadNanoId';
import { useDownloadMessageAttachments } from '../../../../../../../hooks/useDownloadMessageAttachments';

import { PureButtonHelper } from '../../../../../../../../../helpers/buttons/PureButtonHelper';

import {
  attachmentsKeys,
  words
} from '../../../../../../../../../locales/keys';

interface MessageDropdownDownloadAttachmentsProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
}

function MessageDropdownDownloadAttachments({
  message
}: MessageDropdownDownloadAttachmentsProps) {
  const { downloadNanoId } = useDownloadNanoId();

  const { downloadMessageAttachments, downloadMessageAttachmentsLoading } =
    useDownloadMessageAttachments<DownloadMessageAttachmentsQueryResponse>({
      query: DOWNLOAD_MESSAGE_ATTACHMENTS_QUERY
    });

  const handleDownloadAttachments = useCallback<() => void>(
    () =>
      downloadMessageAttachments({
        uuid: message.uuid,
        deviceNanoId: downloadNanoId
      }),
    [message, downloadNanoId, downloadMessageAttachments]
  );

  return (
    <PureButtonHelper
      className="text-left flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      disabled={downloadMessageAttachmentsLoading}
      i18nText={
        size(message.fileAttachments) > 1
          ? words.downloadAll
          : attachmentsKeys.download
      }
      onClick={handleDownloadAttachments}
    />
  );
}

export default MessageDropdownDownloadAttachments;
