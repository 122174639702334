import { MessageUUID } from '../../messagesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface ToggleMessageRedOptions {
  indexCacheKey: string;
  query: string;
}

interface ToggleMessageRedInput {
  uuid: MessageUUID;
}

interface ToggleMessageRedError {
  fullMessages: string[] | null;
}

interface ToggleMessageRedResponse<ToggleMessageRedRecordType> {
  toggleMessageRed: {
    status: string;
    record: ToggleMessageRedRecordType;
    errors: ToggleMessageRedError;
  };
}

const action = 'toggleMessageRed';

function useToggleMessageRed<ToggleMessageRedRecordType>({
  indexCacheKey,
  query
}: ToggleMessageRedOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    ToggleMessageRedInput,
    ToggleMessageRedResponse<ToggleMessageRedRecordType>,
    ToggleMessageRedError,
    ToggleMessageRedRecordType
  >({ action, cacheKeys: [indexCacheKey], query });

  return {
    toggleMessageRedData: updateQueryData,
    toggleMessageRedError: updateQueryError,
    toggleMessageRedLoading: updateQueryLoading,
    toggleMessageRedErrorMessage: updateQueryErrorMessage,
    toggleMessageRed: updateQuery,
    toggleMessageRedReset: updateQueryReset
  };
}

export default useToggleMessageRed;
