import React from 'react';

import { ForwardMessageModalButton } from '../../../../../../modalButtons/ForwardMessageModalButton';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';
import {
  FetchMessagesCacheKey,
  MessageProjectUUID,
  MessageTaskUUID
} from '../../../../../../../messagesTypes';

import { words } from '../../../../../../../../../locales/keys';

interface MessageDropdownForwardModalButtonProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  projectUuid: MessageProjectUUID;
  taskUuid: MessageTaskUUID;
}

function MessageDropdownForwardModalButton({
  message,
  messagesCacheKey,
  projectUuid,
  taskUuid
}: MessageDropdownForwardModalButtonProps) {
  return (
    <ForwardMessageModalButton
      message={message}
      messagesCacheKey={messagesCacheKey}
      projectUuid={projectUuid}
      taskUuid={taskUuid}
      className="text-left flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      i18nText={words.forward}
    />
  );
}

export default MessageDropdownForwardModalButton;
