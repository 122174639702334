import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';
import { ItemMessagesListBillMessageItem } from '../../ItemMessagesListBillMessage.types';
import { ItemMessagesListMessageMenuDropdownMessage } from '../../../ItemMessagesListMessageMenuDropdown';

import { BillMessageDropdownCheckBillButton } from './components/BillMessageDropdownCheckBillButton';

import { CheckPermissions } from '../../../../../../../../../helpers/CheckPermissions';
import { TooltipDropdownHelper } from '../../../../../../../../../helpers/dropdowns/TooltipDropdownHelper';
import { PopoverPlacement } from '../../../../../../../../../helpers/Popover/popoverConstants';

import { MessagesPermissions } from '../../../../../../../messagesConstants';

import { words } from '../../../../../../../../../locales/keys';

interface ItemMessagesListMessageMenuDropdownProps {
  message: ItemMessagesListBillMessageItem &
    ItemMessagesListMessageMenuDropdownMessage;
  messagesCacheKey: FetchMessagesCacheKey;
}

function ItemMessagesListBillMessageMenuDropdown({
  message,
  messagesCacheKey
}: ItemMessagesListMessageMenuDropdownProps) {
  return (
    <TooltipDropdownHelper
      buttonClassName="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
      dropdownPlacement={PopoverPlacement.BOTTOM_END}
      iconClassName="h-6 w-6"
      tooltipI18nText={words.more}
    >
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_CHECK_BILL_BUTTON}
      >
        <BillMessageDropdownCheckBillButton
          message={message}
          messagesCacheKey={messagesCacheKey}
        />
      </CheckPermissions>
    </TooltipDropdownHelper>
  );
}

export default ItemMessagesListBillMessageMenuDropdown;
