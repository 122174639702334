import { IconsEnum } from '../../../../../../../assets/icons/types';
import { PositionStyleEnum } from '../../../../../../../types';
import {
  FetchMessagesCacheKey,
  MessageUUID
} from '../../../../../messagesTypes';

import { MessageLikesPopoverItem } from './components/MessageLikesPopoverItem';

import { TooltipDropdownHelper } from '../../../../../../../helpers/dropdowns/TooltipDropdownHelper';

import { words } from '../../../../../../../locales/keys';
import { messageLikes } from './messageLikesPopoverConstants';

interface ItemMessagesListMessageLikesPopoverProps {
  messageUuid: MessageUUID;
  messagesCacheKey: FetchMessagesCacheKey;
  tooltipSingleton?: boolean;
}

function ItemMessagesListMessageLikesPopover({
  messageUuid,
  messagesCacheKey,
  tooltipSingleton
}: ItemMessagesListMessageLikesPopoverProps) {
  return (
    <TooltipDropdownHelper
      buttonClassName="bg-white dark:bg-gray-900 dark:ring-gray-900 text-gray-400 dark:text-gray-500 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0 flex h-6 items-center px-0.5 ring ring-white rounded-full"
      icon={IconsEnum.SMILE}
      popoverClassName="relative min-w-48 z-10 overflow-y-auto bg-white border border-transparent dark:bg-gray-700 dark:border-opacity-10 dark:border-white dark:text-white focus:outline-none py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-gray-700  fixed"
      popoverPositionStyle={PositionStyleEnum.fixed}
      tooltipI18nText={words.addReaction}
      tooltipSingleton={tooltipSingleton}
    >
      <div className="grid grid-cols-6 gap-0.5 px-1">
        {messageLikes.map(
          ({
            emojiText,
            tooltipI18nText,
            tooltipPlacement,
            messageLikeType
          }) => (
            <MessageLikesPopoverItem
              emojiText={emojiText}
              key={messageLikeType}
              messageUuid={messageUuid}
              messageLikeType={messageLikeType}
              messagesCacheKey={messagesCacheKey}
              tooltipI18nText={tooltipI18nText}
              tooltipPlacement={tooltipPlacement}
            />
          )
        )}
      </div>
    </TooltipDropdownHelper>
  );
}

export default ItemMessagesListMessageLikesPopover;
