import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import { PositionStyleEnum } from '../../../../../../../../../types';

import { useCurrentUser } from '../../../../../../../../../auth/hooks/useAuth';

import { DeleteSelectedLifestyleButton } from '../../../../../../../../selectedLifestyles/components/buttons/DeleteSelectedLifestyleButton';
import { DownloadLifestyleModalButton } from '../../../../../../../../lifestyles/components/buttons/DownloadLifestyleModalButton';
import { MessagesListMessageImageSelectedLifestyleAttachment } from '../../../MessagesListMessageImage';

import { TooltipDropdownHelper } from '../../../../../../../../../helpers/dropdowns/TooltipDropdownHelper';
import { PopoverPlacement } from '../../../../../../../../../helpers/Popover/popoverConstants';

import { words } from '../../../../../../../../../locales/keys';

import { MessagesPermissions } from '../../../../../../../messagesConstants';
import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';

interface MessagesListMessageImageLifestyleButtonsProps {
  attachment: MessagesListMessageImageSelectedLifestyleAttachment;
  messagesCacheKey?: FetchMessagesCacheKey;
}

function MessagesListMessageImageLifestyleButtons({
  attachment,
  messagesCacheKey
}: MessagesListMessageImageLifestyleButtonsProps) {
  const currentUser = useCurrentUser();

  const withDownloadButton =
    attachment?.lifestyle?.uuid &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_DOWNLOAD_SELECTED_LIFESTYLE_BUTTON
    );

  const withDeleteButton =
    attachment.uuid &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_DELETE_SELECTED_LIFESTYLE_BUTTON
    );

  if (!withDownloadButton && !withDeleteButton) {
    return null;
  }

  return (
    <div className="absolute top-2 right-1.5 opacity-0 group-hover/item:opacity-100 bg-white dark:bg-gray-800 rounded-md p-0.5 flex gap-0.5 shadow">
      <div className="relative">
        <TooltipDropdownHelper
          className="relative flex items-center justify-center flex"
          buttonClassName="py-1 pl-1 pr-1 rounded-md bg-white dark:bg-gray-800 leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
          icon={IconsEnum.DOTS_HORIZONTAL_OUTLINE}
          popoverPositionStyle={PositionStyleEnum.fixed}
          tooltipI18nText={words.more}
          dropdownPlacement={PopoverPlacement.AUTO_END}
        >
          {withDownloadButton && (
            <DownloadLifestyleModalButton
              lifestyleUuid={attachment?.lifestyle?.uuid}
              className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
              i18nText={words.download}
            />
          )}
          {withDeleteButton && (
            <DeleteSelectedLifestyleButton
              cacheKey={messagesCacheKey}
              selectedLifestyleUuid={attachment?.uuid}
              className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
              i18nText={words.remove}
            />
          )}
        </TooltipDropdownHelper>
      </div>
    </div>
  );
}

export default MessagesListMessageImageLifestyleButtons;
