import { ModelLightboxTitleContent } from './components/ModelLightboxTitleContent';

interface AttachmentWithProduct {
  product: {
    name: string;
    nda: boolean;
    brand: {
      localizedName: string;
    };
  };
  lifestyle?: never;
  material?: never;
}

interface AttachmentWithLifestyle {
  product?: never;
  lifestyle: {
    name: string;
    nda: boolean;
    category: {
      localizedName: string;
    };
  };
  material?: never;
}

interface AttachmentWithMaterial {
  product?: never;
  lifestyle?: never;
  material: {
    name: string;
    nda: boolean;
  };
}

type Attachment =
  | AttachmentWithProduct
  | AttachmentWithLifestyle
  | AttachmentWithMaterial;

interface ModelLightboxTitleProps {
  attachment: Attachment;
}

function ModelLightboxTitle({ attachment }: ModelLightboxTitleProps) {
  if (attachment.product) {
    return (
      <ModelLightboxTitleContent
        name={attachment.product.name}
        nda={attachment.product.nda}
        brandName={attachment.product.brand?.localizedName}
      />
    );
  }

  if (attachment.lifestyle) {
    return (
      <ModelLightboxTitleContent
        name={attachment.lifestyle.name}
        nda={attachment.lifestyle.nda}
        categoryName={attachment.lifestyle.category?.localizedName}
      />
    );
  }

  if (attachment.material) {
    return (
      <ModelLightboxTitleContent
        name={attachment.material.name}
        nda={attachment.material.nda}
      />
    );
  }
}

export default ModelLightboxTitle;
