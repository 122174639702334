import { gql } from 'graphql-request';

// TODO draft SendEmailNotification
export const MESSAGE_SEND_TO_EMAIL_NOTIFICATION_QUERY = gql`
  mutation MessageSendEmailNotification($uuid: ID!) {
    messageSendEmailNotification(input: { uuid: $uuid }) {
      errors {
        fullMessages
      }
      recordUuid
      status
    }
  }
`;
