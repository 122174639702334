import React from 'react';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';
import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';

import { useToggleMessageRed } from '../../../../../../../hooks/useToggleMessageRed';

import { PureButtonHelper } from '../../../../../../../../../helpers/buttons/PureButtonHelper';

import {
  TOGGLE_MESSAGE_RED_QUERY,
  ToggleMessageRedRecordType
} from '../../../../../../../queries/toggleMessageRed.query';

import { words } from '../../../../../../../../../locales/keys';

interface MessageDropdownRedButtonProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
  messagesCacheKey: FetchMessagesCacheKey;
}

function MessageDropdownRedButton({
  message,
  messagesCacheKey
}: MessageDropdownRedButtonProps) {
  const { toggleMessageRed, toggleMessageRedLoading } =
    useToggleMessageRed<ToggleMessageRedRecordType>({
      indexCacheKey: messagesCacheKey,
      query: TOGGLE_MESSAGE_RED_QUERY
    });

  const handleToggleMessageRed = () => toggleMessageRed({ uuid: message.uuid });

  return (
    <PureButtonHelper
      className="text-left flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      disabled={toggleMessageRedLoading}
      i18nText={words.invisibleForWorker}
      onClick={handleToggleMessageRed}
    />
  );
}

export default MessageDropdownRedButton;
