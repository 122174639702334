import React, { useCallback } from 'react';
import size from 'lodash/size';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';

import {
  DownloadMessageFileAttachmentsQueryResponse,
  DOWNLOAD_MESSAGE_FILE_ATTACHMENTS_QUERY
} from '../../../../../../../queries/downloadMessageFileAttachments.query';

import { useDownloadNanoId } from '../../../../../../../../downloads/hooks/useDownloadNanoId';
import { useDownloadMessageFileAttachments } from '../../../../../../../hooks/useDownloadMessageFileAttachments';

import { PureButtonHelper } from '../../../../../../../../../helpers/buttons/PureButtonHelper';

import {
  attachmentsKeys,
  words
} from '../../../../../../../../../locales/keys';

interface MessageDropdownDownloadFileAttachmentProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
}

function MessageDropdownDownloadFileAttachment({
  message
}: MessageDropdownDownloadFileAttachmentProps) {
  const { downloadNanoId } = useDownloadNanoId();

  const {
    downloadMessageFileAttachments,
    downloadMessageFileAttachmentsLoading
  } = useDownloadMessageFileAttachments<DownloadMessageFileAttachmentsQueryResponse>(
    {
      query: DOWNLOAD_MESSAGE_FILE_ATTACHMENTS_QUERY
    }
  );

  const handleDownloadTaskResults = useCallback<() => void>(
    () =>
      downloadMessageFileAttachments({
        uuid: message.uuid,
        deviceNanoId: downloadNanoId
      }),
    [message, downloadNanoId, downloadMessageFileAttachments]
  );

  return (
    <PureButtonHelper
      className="text-left flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      disabled={downloadMessageFileAttachmentsLoading}
      i18nText={
        size(message.fileAttachments) > 1
          ? words.downloadAll
          : attachmentsKeys.download
      }
      onClick={handleDownloadTaskResults}
    />
  );
}

export default MessageDropdownDownloadFileAttachment;
