import { PositionStyleEnum } from '../../../../../../../../../types';
import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';

import { useCurrentUser } from '../../../../../../../../../auth/hooks/useAuth';

import { DeleteSelectedProductButton } from '../../../../../../../../selectedProducts/components/buttons/DeleteSelectedProductButton';
import { DownloadProductModalButton } from '../../../../../../../../products/components/buttons/DownloadProductModalButton';
import { MessagesListMessageImageSelectedProductAttachment } from '../../../MessagesListMessageImage';

import { TooltipDropdownHelper } from '../../../../../../../../../helpers/dropdowns/TooltipDropdownHelper';
import { PopoverPlacement } from '../../../../../../../../../helpers/Popover/popoverConstants';

import { words } from '../../../../../../../../../locales/keys';

import { MessagesPermissions } from '../../../../../../../messagesConstants';

interface MessagesListMessageImageProductButtonsProps {
  attachment: MessagesListMessageImageSelectedProductAttachment;
  messagesCacheKey?: FetchMessagesCacheKey;
}

function MessagesListMessageImageProductButtons({
  attachment,
  messagesCacheKey
}: MessagesListMessageImageProductButtonsProps) {
  const currentUser = useCurrentUser();

  const withDownloadButton =
    attachment?.product?.uuid &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_DOWNLOAD_SELECTED_PRODUCT_BUTTON
    );

  const withDeleteButton =
    attachment.uuid &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_DELETE_SELECTED_PRODUCT_BUTTON
    );

  if (!withDownloadButton && !withDeleteButton) {
    return null;
  }

  return (
    <div className="absolute top-2 right-1.5 opacity-0 group-hover/item:opacity-100 bg-white dark:bg-gray-800 rounded-md p-0.5 flex gap-0.5 shadow">
      <div className="relative">
        <TooltipDropdownHelper
          buttonClassName="py-1 pl-1 pr-1 rounded-md bg-white dark:bg-gray-800 leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
          icon={IconsEnum.DOTS_HORIZONTAL_OUTLINE}
          popoverPositionStyle={PositionStyleEnum.fixed}
          tooltipI18nText={words.more}
          dropdownPlacement={PopoverPlacement.AUTO_END}
        >
          {withDownloadButton && (
            <DownloadProductModalButton
              productUuid={attachment?.product?.uuid}
              className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
              i18nText={words.download}
            />
          )}
          {withDeleteButton && (
            <DeleteSelectedProductButton
              cacheKey={messagesCacheKey}
              selectedProductUuid={attachment?.uuid}
              className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
              i18nText={words.remove}
            />
          )}
        </TooltipDropdownHelper>
      </div>
    </div>
  );
}

export default MessagesListMessageImageProductButtons;
