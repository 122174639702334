import React, { useCallback } from 'react';

import { PureButtonHelper } from '../../../../../../../../../helpers/buttons/PureButtonHelper';

import { useDownloadNanoId } from '../../../../../../../../downloads/hooks/useDownloadNanoId';
import { useDownloadMessageSelectedLifestyles } from '../../../../../../../hooks/useDownloadMessageSelectedLifestyles';

import {
  DownloadMessageSelectedLifestylesQueryResponse,
  DOWNLOAD_MESSAGE_SELECTED_LIFESTYLES_QUERY
} from '../../../../../../../queries/downloadMessageSelectedLifestyles.query';

import { selectedLifestylesKeys } from '../../../../../../../../../locales/keys';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';

interface MessageDropdownDownloadSelectedLifestyleProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
}

function MessageDropdownDownloadSelectedLifestyles({
  message
}: MessageDropdownDownloadSelectedLifestyleProps) {
  const { downloadNanoId } = useDownloadNanoId();

  const {
    downloadMessageSelectedLifestyles,
    downloadMessageSelectedLifestylesLoading
  } = useDownloadMessageSelectedLifestyles<DownloadMessageSelectedLifestylesQueryResponse>(
    {
      query: DOWNLOAD_MESSAGE_SELECTED_LIFESTYLES_QUERY
    }
  );

  const handleDownloadTaskResults = useCallback<() => void>(
    () =>
      downloadMessageSelectedLifestyles({
        uuid: message.uuid,
        deviceNanoId: downloadNanoId
      }),
    [message, downloadNanoId, downloadMessageSelectedLifestyles]
  );

  return (
    <PureButtonHelper
      className="text-left flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      disabled={downloadMessageSelectedLifestylesLoading}
      i18nText={selectedLifestylesKeys.download}
      onClick={handleDownloadTaskResults}
    />
  );
}

export default MessageDropdownDownloadSelectedLifestyles;
