import { MessageUUID, FetchMessagesCacheKey } from '../../messagesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface MessageSendEmailNotificationOptions {
  cacheKeys: FetchMessagesCacheKey[];
  query: string;
}

interface MessageSendEmailNotificationInput {
  uuid: MessageUUID;
}

interface MessageSendEmailNotificationError {
  fullMessages: string[] | null;
}

interface MessageSendEmailNotificationResponse<
  MessageSendEmailNotificationRecordType
> {
  messageSendEmailNotification: {
    status: string;
    record: MessageSendEmailNotificationRecordType;
    errors: MessageSendEmailNotificationError;
  };
}

const action = 'messageSendEmailNotification';

// TODO draft SendEmailNotification
function useMessageSendEmailNotification<
  MessageSendEmailNotificationRecordType
>({ cacheKeys, query }: MessageSendEmailNotificationOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    MessageSendEmailNotificationInput,
    MessageSendEmailNotificationResponse<MessageSendEmailNotificationRecordType>,
    MessageSendEmailNotificationError,
    MessageSendEmailNotificationRecordType
  >({ action, cacheKeys, query });

  return {
    messageSendEmailNotificationData: updateQueryData,
    messageSendEmailNotificationError: updateQueryError,
    messageSendEmailNotificationLoading: updateQueryLoading,
    messageSendEmailNotificationErrorMessage: updateQueryErrorMessage,
    messageSendEmailNotification: updateQuery,
    messageSendEmailNotificationReset: updateQueryReset
  };
}

export default useMessageSendEmailNotification;
