import { useCallback } from 'react';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { I18nText } from '../../../../../../../types';
import { MessageUUID } from '../../../../../messagesTypes';

import {
  DownloadMessageFileAttachmentsQueryResponse,
  DOWNLOAD_MESSAGE_FILE_ATTACHMENTS_QUERY
} from '../../../../../queries/downloadMessageFileAttachments.query';

import { useDownloadNanoId } from '../../../../../../downloads/hooks/useDownloadNanoId';
import { useDownloadMessageFileAttachments } from '../../../../../hooks/useDownloadMessageFileAttachments';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

interface ItemMessagesListMessageDownloadFileAttachmentsButtonProps {
  uuid: MessageUUID;
  tooltipI18nText: I18nText;
  tooltipSingleton?: boolean;
}

function ItemMessagesListMessageDownloadFileAttachmentsButton({
  uuid,
  tooltipI18nText,
  tooltipSingleton
}: ItemMessagesListMessageDownloadFileAttachmentsButtonProps) {
  const { downloadNanoId } = useDownloadNanoId();

  const {
    downloadMessageFileAttachments,
    downloadMessageFileAttachmentsLoading
  } = useDownloadMessageFileAttachments<DownloadMessageFileAttachmentsQueryResponse>(
    {
      query: DOWNLOAD_MESSAGE_FILE_ATTACHMENTS_QUERY
    }
  );

  const handleDownloadResults = useCallback<() => void>(
    () =>
      downloadMessageFileAttachments({
        uuid: uuid,
        deviceNanoId: downloadNanoId
      }),
    [uuid, downloadNanoId, downloadMessageFileAttachments]
  );

  return (
    <PureTooltipIconButtonHelper
      className="text-gray-400 dark:text-gray-500 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0 p-0.5 rounded-md inline-flex"
      icon={IconsEnum.DOWNLOAD_OUTLINE}
      iconClassName="w-5 h-5"
      onClick={handleDownloadResults}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={TooltipPlacement.TOP}
      disabled={downloadMessageFileAttachmentsLoading}
      tooltipSingleton={tooltipSingleton}
    />
  );
}

export default ItemMessagesListMessageDownloadFileAttachmentsButton;
