import {
  ID,
  UUID,
  CreatedAt,
  UpdatedAt,
  DateType,
  FileUrl,
  FetchItemsGqlQuery,
  FetchItemsCacheKey,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsIsPlaceholderData,
  FetchItemsFetchingNextPage,
  FetchItemsHasNextItemsPage,
  FetchItemsLimit,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsLoadMoreItems,
  FetchItemsTotalCount,
  UUIDFilter,
  BooleanFilter,
  FetchItemGqlQuery,
  CreateItemGqlQuery,
  UpdateItemGqlQuery,
  DeleteItemGqlQuery,
  CreateItemGqlStatus,
  UpdateItemGqlStatus,
  DeleteItemGqlStatus,
  ItemGqlError,
  FetchItemCacheKey,
  FetchItemsPage,
  FetchItemsShowItem,
  FetchItemsEditItem,
  DeviceNanoId,
  HexColor,
  NanoIDFilter,
  TextFilterType,
  FetchItemsFilterItems,
  UUIDsFilter,
  TagIDsFilter,
  DateFilterType,
  FetchItemsClearItemsFilters,
  CreateItemCacheKeys,
  IdFilterType,
  NanoID
} from '../../types';

import {
  BillID,
  BillUUID,
  BillAmount,
  BillCurrency,
  BillStatus,
  BillBody,
  BillCreatedAt,
  BillPayerUUID,
  BillPayerFullName,
  BillPayerPayoneerCheckoutAddress,
  BillPayable,
  BillPaymentForm,
  BillPaymentUrl,
  BillAccount,
  BillPayerNanoID,
  BillPayerClient,
  BillPayerCurrentTeamNanoID
} from '../bills/billsTypes';

import {
  ProjectID,
  ProjectName,
  ProjectNanoID,
  ProjectUUID
} from '../projects/projectsTypes';

import {
  TaskID,
  TaskMark,
  TaskName,
  TaskNanoID,
  TaskStatus,
  TaskSupportMark,
  TaskUUID
} from '../tasks/tasksTypes';

import {
  UserUUID,
  UserClient,
  UserFullName,
  UserImageUUID,
  UserImageFile,
  UserNanoID,
  UserID,
  UserCurrentTeamNanoID
} from '../users/usersTypes';

import {
  FileAttachmentCreatedAt,
  FileAttachmentDrawing,
  FileAttachmentExt,
  FileAttachmentFile,
  FileAttachmentID,
  FileAttachmentName,
  FileAttachmentReference,
  FileAttachmentSize,
  FileAttachmentTexture,
  FileAttachmentType,
  FileAttachmentUUID,
  FileAttachmentWidth,
  FileAttachmentHeight,
  FileAttachmentDPI
} from '../fileAttachments/fileAttachmentsTypes';

import {
  SelectedProductID,
  SelectedProductProductBrand,
  SelectedProductProductName,
  SelectedProductProductNda,
  SelectedProductUUID
} from '../selectedProducts/selectedProductsTypes';

import {
  SelectedLifestyleID,
  SelectedLifestyleLifestyleCategoryLocalizedName,
  SelectedLifestyleLifestyleName,
  SelectedLifestyleLifestyleNda,
  SelectedLifestyleUUID
} from '../selectedLifestyles/selectedLifestylesTypes';

import {
  SelectedMaterialID,
  SelectedMaterialMaterialName,
  SelectedMaterialMaterialNda,
  SelectedMaterialUUID
} from '../selectedMaterials/selectedMaterialsTypes';

import {
  InvoiceAmount,
  InvoiceCreatedAt,
  InvoiceDueDate,
  InvoiceID,
  InvoiceInvoiceType,
  InvoiceNanoID,
  InvoicePaymentMethods,
  InvoicePrepayment,
  InvoicePrepaymentAmount,
  InvoiceReturnReasons,
  InvoiceStatus,
  InvoiceTotal,
  InvoiceUpdatedAt,
  InvoiceUUID,
  FetchInvoicesFetched,
  FetchInvoicesErrorMessage
} from '../invoices/invoicesTypes';

import { TeamNanoID } from '../teams/teamsTypes';

export type MessageID = ID;
export type MessageIDs = MessageID[];
export type MessageUUID = UUID;
export type MessageNanoId = NanoID;
export type MessageBody = string;
export type MessageHours = number;
export type MessageMinutes = number;
export type MessageMessageType = MessageMessageTypes;
export type MessageFavorite = boolean;
export type MessageFlagged = boolean;
export type MessageRead = boolean;
export type MessageRed = boolean;
export type MessagePinnedAt = DateType;
export type MessageVisibleForClient = boolean;
export type MessageColors = HexColor[];
export type MessageCreatedAt = CreatedAt;
export type MessageUpdatedAt = UpdatedAt;
export type MessageDeviceNanoId = DeviceNanoId;
export type MessageIsResult = boolean;
export type MessageInvoiceID = InvoiceID;
export type MessageInvoiceUUID = InvoiceUUID;
export type MessageInvoiceNanoID = InvoiceNanoID;
export type MessageInvoiceAmount = InvoiceAmount;
export type MessageInvoiceClientID = ID;
export type MessageInvoiceClientStatus = string;
export type MessageInvoicePrepayment = InvoicePrepayment;
export type MessageInvoicePrepaymentAmount = InvoicePrepaymentAmount;
export type MessageInvoiceReturnReason = InvoiceReturnReasons;
export type MessageInvoiceStatus = InvoiceStatus;
export type MessageInvoiceCreatedAt = InvoiceCreatedAt;
export type MessageInvoiceDueDate = InvoiceDueDate;
export type MessageInvoicePaymentMethod = InvoicePaymentMethods;
export type MessageInvoiceUpdatedAt = InvoiceUpdatedAt;
export type MessageInvoiceType = InvoiceInvoiceType;
export type MessageInvoiceTotal = InvoiceTotal;
export type MessageInvoiceFetched = FetchInvoicesFetched;
export type MessageInvoiceErrorMessage = FetchInvoicesErrorMessage;
export type MessageWorkOnProject = boolean;

export enum MessageMessageTypes {
  CLIENT_DESCRIPTION = 'client_description',
  DISCUSSION_MESSAGE = 'discussion_message',
  ADDED_FACILITIES = 'added_facilities',
  TRACK_TIME = 'track_time',
  DISCUSSION_BODY = 'discussion_body',
  BILL_BUTTON = 'bill_button',
  PAID_MESSAGE = 'paid_message',
  ADDED_FILE_ATTACHMENTS = 'added_file_attachments',
  ADDED_SCENES = 'added_scenes',
  RAIFFEISEN_BANK_BUTTON = 'raiffeisen_bank_button',
  RESULTS = 'results',
  TWOCHECKOUT_BUTTON = 'twocheckout_button',
  PAYONEER_BUTTON = 'payoneer_button',
  TASK_DESCRIPTION = 'task_description',
  RIETUMU_BUTTON = 'rietumu_button',
  UPDATED_CLIENT_DESCRIPTION = 'updated_client_description',
  PAYMENT_PROCESSING = 'payment_processing',
  DESTROYED_TODO_ITEM = 'destroyed_todo_item',
  CREATED_TODO_ITEM = 'created_todo_item',
  CHANGED_TODO_ITEM_DONE = 'changed_todo_item_done',
  CHANGED_TODO_ITEM_NAME = 'changed_todo_item_name',
  LIQPAY_BUTTON = 'liqpay_button',
  CANCEL_PAID_MESSAGE = 'cancel_paid_message',
  UPDATED_TASK_DESCRIPTION = 'updated_task_description',
  UPDATED_DISCUSSION_BODY = 'updated_discussion_body',
  INTERKASSA_BUTTON = 'interkassa_button',
  CLUSTER_FOR_REVISION = 'cluster_for_revision',
  FONDY_BUTTON = 'fondy_button',
  MISSING_SENDER = 'missing_sender',
  INVOICE_BUTTON = 'invoice_button',
  EASYPAY_BUTTON = 'easypay_button',
  STRIPE_BUTTON = 'stripe_button',
  EWAY_BUTTON = 'eway_button',
  FRONTEND_SENDING_MESSAGE = 'frontend_sending_message',
  INVOICE_ACTION_MESSAGE = 'invoice_action_message'
}

export type MessageUserUUID = UserUUID;
export type MessageUserID = UserID;
export type MessageUserNanoID = UserNanoID;
export type MessageUserFullName = UserFullName;
export type MessageUserClient = UserClient;
export type MessageUserImageUUID = UserImageUUID;
export type MessageUserImageFile = UserImageFile;
export type MessageUserUserCurrentTeamNanoID = UserCurrentTeamNanoID;

export type MessageProjectID = ProjectID;
export type MessageProjectUUID = ProjectUUID;
export type MessageProjectNanoID = ProjectNanoID;
export type MessageProjectName = ProjectName;
export type MessageTaskID = TaskID;
export type MessageTaskUUID = TaskUUID;
export type MessageTaskNanoID = TaskNanoID;
export type MessageTaskName = TaskName;
export type MessageTaskStatus = TaskStatus;
export type MessageTaskMark = TaskMark;
export type MessageTaskSupportMark = TaskSupportMark;

export type MessageBillID = BillID;
export type MessageBillUUID = BillUUID;
export type MessageBillAccount = BillAccount;
export type MessageBillAmount = BillAmount;
export type MessageBillCurrency = BillCurrency;
export type MessageBillStatus = BillStatus;
export type MessageBillBody = BillBody;
export type MessageBillPayable = BillPayable;
export type MessageBillCreatedAt = BillCreatedAt;
export type MessageBillPayerUUID = BillPayerUUID;
export type MessageBillPayerNanoID = BillPayerNanoID;
export type MessageBillPayerFullName = BillPayerFullName;
export type MessageBillPaymentForm = BillPaymentForm;
export type MessageBillPaymentUrl = BillPaymentUrl;
export type MessageBillPayerPayoneerCheckoutAddress =
  BillPayerPayoneerCheckoutAddress;
export type MessageBillPayerClient = BillPayerClient;
export type MessageBillPayerCurrentTeamNanoID = BillPayerCurrentTeamNanoID;

export type MessagePaidBillID = BillID;
export type MessagePaidBillUUID = BillUUID;
export type MessagePaidBillAmount = BillAmount;
export type MessagePaidBillCurrency = BillCurrency;
export type MessagePaidBillPayerUUID = BillPayerUUID;
export type MessagePaidBillPayerNanoID = BillPayerNanoID;
export type MessagePaidBillPayerFullName = BillPayerFullName;

export type MessageFileAttachmentID = FileAttachmentID;
export type MessageFileAttachmentIDs = MessageFileAttachmentID[];
export type MessageFileAttachmentsUUID = FileAttachmentUUID;
export type MessageFileAttachmentsType = FileAttachmentType;
export type MessageFileAttachmentsName = FileAttachmentName;
export type MessageFileAttachmentsFile = FileAttachmentFile;
export type MessageFileAttachmentsSize = FileAttachmentSize;
export type MessageFileAttachmentsDrawing = FileAttachmentDrawing;
export type MessageFileAttachmentsReference = FileAttachmentReference;
export type MessageFileAttachmentsTexture = FileAttachmentTexture;
export type MessageFileAttachmentsCreatedAt = FileAttachmentCreatedAt;
export type MessageFileAttachmentsExt = FileAttachmentExt;
export type MessageFileAttachmentsWidth = FileAttachmentWidth;
export type MessageFileAttachmentsHeight = FileAttachmentHeight;
export type MessageFileAttachmentsDPI = FileAttachmentDPI;

export type MessageFileAttachment = {
  id: MessageFileAttachmentID;
  uuid: MessageFileAttachmentsUUID;
  type: MessageFileAttachmentsType;
  name: MessageFileAttachmentsName;
  file: MessageFileAttachmentsFile;
  size: MessageFileAttachmentsSize;
  drawing: MessageFileAttachmentsDrawing;
  reference: MessageFileAttachmentsReference;
  texture: MessageFileAttachmentsTexture;
  createdAt: MessageFileAttachmentsCreatedAt;
  ext: MessageFileAttachmentsExt;
  width: MessageFileAttachmentsWidth;
  height: MessageFileAttachmentsHeight;
  dpi: MessageFileAttachmentsDPI;
};

export type MessageFileAttachments = MessageFileAttachment[];

export type MessageSelectedProductID = SelectedProductID;
export type MessageSelectedProductIDs = MessageSelectedProductID[];
export type MessageSelectedProductsUUID = SelectedProductUUID;
export type MessageSelectedProductsType = 'selected_product';
export type MessageSelectedProductsCreatedAt = CreatedAt;
export type MessageSelectedProductsProductUUID = UUID;
export type MessageSelectedProductsProductImageUUID = UUID;
export type MessageSelectedProductsProductImageName = string;
export type MessageSelectedProductsProductName = SelectedProductProductName;
export type MessageSelectedProductsProductNda = SelectedProductProductNda;
export type MessageSelectedProductsProductBrand = SelectedProductProductBrand;
export type MessageSelectedProductsProductImageFile = FileUrl;
export type MessageSelectedLifestyleID = SelectedLifestyleID;
export type MessageSelectedLifestyleIDs = MessageSelectedLifestyleID[];
export type MessageSelectedLifestylesUUID = SelectedLifestyleUUID;
export type MessageSelectedLifestylesType = 'selected_lifestyle';
export type MessageSelectedLifestylesCreatedAt = CreatedAt;
export type MessageSelectedLifestylesLifestyleUUID = UUID;
export type MessageSelectedLifestylesLifestyleName =
  SelectedLifestyleLifestyleName;
export type MessageSelectedLifestylesLifestyleNda =
  SelectedLifestyleLifestyleNda;
export type MessageSelectedLifestylesLifestyleCategoryLocalizedName =
  SelectedLifestyleLifestyleCategoryLocalizedName;
export type MessageSelectedLifestylesLifestyleImageUUID = UUID;
export type MessageSelectedLifestylesLifestyleImageName = string;
export type MessageSelectedLifestylesLifestyleImageFile = FileUrl;
export type MessageSelectedMaterialID = SelectedMaterialID;
export type MessageSelectedMaterialsUUID = SelectedMaterialUUID;
export type MessageSelectedMaterialsType = 'selected_material';
export type MessageSelectedMaterialsCreatedAt = CreatedAt;
export type MessageSelectedMaterialsMaterialUUID = UUID;
export type MessageSelectedMaterialsMaterialNda = SelectedMaterialMaterialNda;
export type MessageSelectedMaterialsMaterialName = SelectedMaterialMaterialName;
export type MessageSelectedMaterialsMaterialImageUUID = UUID;
export type MessageSelectedMaterialsMaterialImageName = string;
export type MessageSelectedMaterialsMaterialImageFile = FileUrl;

export type MessageLikeUUID = UUID;
export type MessageLikeType = string;
export type MessageLikeCount = number;
export type MessageLikeEmojiText = string;
export type MessageLikeUserID = UserID;
export type MessageLikeUserUUID = UserImageUUID;
export type MessageLikeUserFullName = UserFullName;
export type MessageLikeUserImageFile = UserImageFile;
export type MessageLikeUserImage = {
  file: MessageLikeUserImageFile;
};
export type MessageLikeUser = {
  uuid: MessageLikeUserUUID;
  fullName: MessageLikeUserFullName;
  image: MessageLikeUserImage | null;
};
export type MessageLikeUsers = MessageLikeUser[];
export type MessageLikeItem = {
  uuid: MessageLikeUUID;
  messageLikeType: MessageLikeType;
  userId: MessageLikeUserID;
  user: MessageLikeUser;
};
export type MessageLikeItems = MessageLikeItem[];

export type MessageForwardedMessageProjectNanoID = MessageProjectNanoID;
export type MessageForwardedMessageProjectName = MessageProjectName;
export type MessageForwardedMessageTaskNanoID = MessageTaskNanoID;
export type MessageForwardedMessageTaskName = MessageTaskName;

export type MessageBodyFilter = TextFilterType;
export type MessageUserUUIDsFilter = UUIDsFilter;
export type MessageCreatedAtFilter = DateFilterType;
export type MessageTagIdsFilter = TagIDsFilter;
export type MessageFavoriteFilter = BooleanFilter;
export type MessageFlaggedFilter = BooleanFilter;
export type MessageVisibleForClientFilter = BooleanFilter;
export type MessageWithAttachmentsFilter = BooleanFilter;
export type MessageWithHoursOrMinutesFilter = BooleanFilter;
export type MessageWithoutHoursAndMinutesFilter = BooleanFilter;
export type MessageProjectMemberFilter = BooleanFilter;
export type MessageTaskMemberFilter = BooleanFilter;
export type MessageUnreadFilter = BooleanFilter;
export type MessageIdsFilter = IdFilterType;

export const enum MessageFields {
  ID = 'id',
  UUID = 'uuid',
  BODY = 'body',
  VISIBLE_FOR_CLIENT = 'visibleForClient',
  FILE_ATTACHMENT_IDS = 'fileAttachmentIds',
  ATTACHED_FILE_ATTACHMENT_IDS = 'attachedFileAttachmentIds',
  SELECTED_PRODUCT_IDS = 'selectedProductIds',
  SELECTED_LIFESTYLE_IDS = 'selectedLifestyleIds',
  COLORS = 'colors',
  CREATED_AT = 'createdAt'
}

export const enum FetchMessagesFilterFields {
  ID = 'id',
  BODY = 'body',
  CREATED_AT = 'createdAt',
  FAVORITE = 'favorite',
  FLAGGED = 'flagged',
  ONLY_CLIENTS = 'onlyClients',
  PROJECT_MEMBER = 'projectMember',
  TAG_IDS = 'tagIds',
  TASK_MEMBER = 'taskMember',
  UNREAD = 'unread',
  USER_UUIDS = 'userUuids',
  VISIBLE_FOR_CLIENT = 'visibleForClient',
  WITH_ATTACHMENTS = 'withAttachments'
}

export const enum ForwardMessageFields {
  UUID = 'uuid',
  BODY = 'body',
  VISIBLE_FOR_CLIENT = 'visibleForClient',
  INCLUDES_ATTACHMENTS = 'includesAttachments',
  PROJECT_UUID = 'projectUuid',
  TASK_UUIDS = 'taskUuids'
}

export interface FetchMessagesFilters {
  body?: MessageBodyFilter;
  createdAt?: MessageCreatedAtFilter;
  favorite?: MessageFavoriteFilter;
  flagged?: MessageFlaggedFilter;
  flaggedOrPinned?: BooleanFilter;
  onlyClients?: BooleanFilter;
  pinned?: BooleanFilter;
  projectMember?: MessageProjectMemberFilter;
  projectNanoId?: NanoIDFilter;
  projectUuid?: UUIDFilter;
  tagIds?: MessageTagIdsFilter;
  taskMember?: MessageTaskMemberFilter;
  taskNanoId?: NanoIDFilter;
  taskUuid?: UUIDFilter;
  unread?: MessageUnreadFilter;
  userUuids?: MessageUserUUIDsFilter;
  visibleForClient?: MessageVisibleForClientFilter;
  withAttachments?: MessageWithAttachmentsFilter;
  withHoursOrMinutes?: MessageWithHoursOrMinutesFilter;
  withoutHoursAndMinutes?: MessageWithoutHoursAndMinutesFilter;
  id?: MessageIdsFilter;
  teamNanoIds?: TeamNanoID[];
}

export const enum FetchMessagesSortableFields {
  ID = 'id',
  CREATED_AT = 'createdAt'
}

export const enum FetchMessagesSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type FetchMessagesFetched = FetchItemsFetched;
export type FetchMessagesErrorMessage = FetchItemsErrorMessage;
export type FetchMessagesIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchMessagesFetchingNextPage = FetchItemsFetchingNextPage;
export type FetchMessagesHasNextMessagesPage = FetchItemsHasNextItemsPage;
export type FetchMessagesPage = FetchItemsPage;
export type FetchMessagesLimit = FetchItemsLimit;
export type FetchMessagesSort = FetchItemsSort; // FetchMessagesSortTypes[];
export type FetchMessagesTotalCount = FetchItemsTotalCount;
export type FetchMessagesSortMessages = FetchItemsSortItems;
export type FetchMessagesPaginateMessages = FetchItemsPaginateItems;
export type FetchMessagesPrefetchMessages = FetchItemsPrefetchItems;
export type FetchMessagesLoadMoreMessages = FetchItemsLoadMoreItems;
export type FetchMessagesShowMessage = FetchItemsShowItem;
export type FetchMessagesEditMessage = FetchItemsEditItem;

export type FetchMessagesGqlQuery = FetchItemsGqlQuery;

export type FetchMessageCacheKey = FetchItemCacheKey;
export type FetchMessagesCacheKey = FetchItemsCacheKey;
export type ForwardMessageIndexCacheKey = UpdateItemGqlQuery;

export type FetchMessageGqlQuery = FetchItemGqlQuery;
export type CreateMessageInProjectGqlQuery = CreateItemGqlQuery;
export type CreateMessageInTaskGqlQuery = CreateItemGqlQuery;
export type CreateMessageWithTaskNanoIdGqlQuery = CreateItemGqlQuery;
export type CreateMessageWithProjectNanoIdGqlQuery = CreateItemGqlQuery;
export type UpdateMessageGqlQuery = UpdateItemGqlQuery;
export type DeleteMessageGqlQuery = DeleteItemGqlQuery;
export type DownloadMessageAttachmentsGqlQuery = UpdateItemGqlQuery;
export type DownloadMessageFileAttachmentsGqlQuery = UpdateItemGqlQuery;
export type DownloadMessageSelectedProductsGqlQuery = UpdateItemGqlQuery;
export type DownloadMessageSelectedLifestylesGqlQuery = UpdateItemGqlQuery;
export type ForwardMessageGqlQuery = UpdateItemGqlQuery;
export type AddToDescriptionMessageGqlQuery = UpdateItemGqlQuery;

export type CreateMessageInProjectGqlStatus = CreateItemGqlStatus;
export type CreateMessageInTaskGqlStatus = CreateItemGqlStatus;
export type CreateMessageWithTaskNanoIdGqlStatus = CreateItemGqlStatus;
export type CreateMessageWithProjectNanoIdGqlStatus = CreateItemGqlStatus;
export type UpdateMessageGqlStatus = UpdateItemGqlStatus;
export type DeleteMessageGqlStatus = DeleteItemGqlStatus;
export type DownloadMessageAttachmentsGqlStatus = UpdateItemGqlStatus;
export type DownloadMessageFileAttachmentsGqlStatus = UpdateItemGqlStatus;
export type DownloadMessageSelectedProductsGqlStatus = UpdateItemGqlStatus;
export type DownloadMessageSelectedLifestylesGqlStatus = UpdateItemGqlStatus;
export type ForwardMessageGqlStatus = UpdateItemGqlStatus;
export type AddToDescriptionMessageGqlStatus = UpdateItemGqlStatus;
export type MessageGqlError = ItemGqlError;

export type FetchMessagesInProjectCacheKey = FetchItemsCacheKey;
export type FetchMessagesInTaskCacheKey = FetchItemsCacheKey;
export type FetchMessageInProjectCacheKey = FetchItemCacheKey;
export type FetchMessageInTaskCacheKey = FetchItemCacheKey;

export type ToggleMessageLikeGqlStatus = UpdateItemGqlStatus;
export type FetchMessagesFilterMessages =
  FetchItemsFilterItems<FetchMessagesFilters>;
export type FetchMessagesClearMessagesFilters = FetchItemsClearItemsFilters;

export type CreateMessageWithTaskNanoIdCacheKeys = CreateItemCacheKeys;
export type CreateMessageWithProjectNanoIdCacheKeys = CreateItemCacheKeys;
