import React, { useCallback } from 'react';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';

import { PureButtonHelper } from '../../../../../../../../../helpers/buttons/PureButtonHelper';

import { copyToClipboard } from '../../../../../../../../../utils/copyToClipboard';

import { words } from '../../../../../../../../../locales/keys';

interface MessageDropdownCopyIdButtonProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
}

function MessageDropdownCopyIdButton({
  message
}: MessageDropdownCopyIdButtonProps) {
  const handleCopy = useCallback<() => void>(
    () => copyToClipboard(message.id),
    [message]
  );

  return (
    <PureButtonHelper
      className="text-left flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      i18nText={words.copyId}
      onClick={handleCopy}
    />
  );
}

export default MessageDropdownCopyIdButton;
