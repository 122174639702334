import { PositionStyleEnum } from '../../../../../../../../../types';
import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';

import { useCurrentUser } from '../../../../../../../../../auth/hooks/useAuth';

import { DeleteSelectedMaterialButton } from '../../../../../../../../selectedMaterials/components/buttons/DeleteSelectedMaterialButton';
import { MessagesListMessageImageSelectedMaterialAttachment } from '../../../MessagesListMessageImage';

import { TooltipDropdownHelper } from '../../../../../../../../../helpers/dropdowns/TooltipDropdownHelper';
import { PopoverPlacement } from '../../../../../../../../../helpers/Popover/popoverConstants';

import { words } from '../../../../../../../../../locales/keys';

import { MessagesPermissions } from '../../../../../../../messagesConstants';
import DownloadMaterialModalButton from '../../../../../../../../materials/components/buttons/DownloadMaterialModalButton/DownloadMaterialModalButton';

interface MessagesListMessageImageMaterialButtonsProps {
  attachment: MessagesListMessageImageSelectedMaterialAttachment;
  messagesCacheKey?: FetchMessagesCacheKey;
}

function MessagesListMessageImageMaterialButtons({
  attachment,
  messagesCacheKey
}: MessagesListMessageImageMaterialButtonsProps) {
  const currentUser = useCurrentUser();

  const withDownloadButton =
    attachment.material?.uuid &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_DOWNLOAD_SELECTED_MATERIAL_BUTTON
    );

  const withDeleteButton =
    attachment.uuid &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_DELETE_SELECTED_MATERIAL_BUTTON
    );

  if (!withDownloadButton && !withDeleteButton) {
    return null;
  }

  return (
    <div className="absolute z-20 top-0 right-0 group-hover/item:opacity-100 opacity-0 p-1 transition duration-150 hover:ease-in">
      <div className="relative">
        <TooltipDropdownHelper
          buttonClassName="py-1 pl-1 pr-1 rounded-md bg-white dark:bg-gray-800 leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
          icon={IconsEnum.DOTS_HORIZONTAL_OUTLINE}
          popoverPositionStyle={PositionStyleEnum.fixed}
          tooltipI18nText={words.more}
          dropdownPlacement={PopoverPlacement.AUTO_END}
        >
          {withDownloadButton && (
            <DownloadMaterialModalButton
              materialUuid={attachment.material?.uuid}
              className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
              i18nText={words.download}
            />
          )}
          {withDeleteButton && (
            <DeleteSelectedMaterialButton
              cacheKey={messagesCacheKey}
              selectedMaterialUuid={attachment.uuid}
              className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
              i18nText={words.remove}
            />
          )}
        </TooltipDropdownHelper>
      </div>
    </div>
  );
}

export default MessagesListMessageImageMaterialButtons;
